import React from "react";

class Title extends React.Component {
    render () {
        return (
            <h1>Pair Programming Timekeeper</h1>
        );
    }
}

export default Title;
